import Swiper, {Navigation, Pagination, Autoplay} from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay]);

export default function sliders() {
    (function($) {
        $(window).on('load', function () {
           // get all sliders, we need to loop them due to different settings + nav
            var swipers = document.querySelectorAll('.swiper:not(.control):not(.mobile)');
            swipers.forEach(function(el,index){
                var closestSection = el.closest('section');
                var controls = closestSection.querySelector('.control');
    
                // slider settings
                var options = {
                    speed: 600,
                    loop: true,
                    slidesPerView: 1,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: true,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    thumbs:{},
                    centeredSlides: true
                };
        
                if(el.classList.contains('single')){
                    options.breakpoints = {

                    };
                }
    
                // init slider
                new Swiper(el, options);
            });
        });
    
    })( jQuery );
}

