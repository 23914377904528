export default function tabs(){
	var tabControls = document.querySelectorAll('[data-tabcontrol]');
	var tabsToToggle = document.querySelectorAll('[data-tab], [data-tabcontrol]');

	tabControls.forEach(function(parentEl, index1){
		parentEl.addEventListener('click', function(e){
			parentEl.classList.remove('active');

			tabsToToggle.forEach(function(el, index){

				if(el.dataset.tab == parentEl.dataset.tabcontrol){
					el.classList.add('active');
				}else{
					el.classList.remove('active');
				}
			});
		});
	});
}