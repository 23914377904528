export default function phoneConcat() {
    (function($) {
            if(window.screen.width > 1000) {
                // Phone Concatenation Script For Tracking
                setTimeout(function () {
                    $('.phone-text em').each(function () {
                        var unsliced = $(this).text();
                        var sliced = unsliced.slice(0, -2) + "...";
                        $(this).text(sliced);
                        var linked = "tel:" + unsliced.replace(/\s/g, '');
                        $(this).click(function () {
                            $(this).text(unsliced);
                        });
                    });
        
                }, 2000)
            }
    })( jQuery );

    const headerPhone = document.querySelector(".header-phone");
    const headerPhoneText = headerPhone.querySelector(".phone-text");

    headerPhone.addEventListener('click', e => {
        headerPhoneText.classList.remove('d-none')
    })
}
